<template>
  <v-container
    :key="refresh"
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-snackbar
      v-model="bulkUploadSuccess"
      data-testid="bulkUploadSuccessToast"
      color="primary"
      position="fixed"
    >
      Partners uploaded successfully!
      <template v-slot:action>
        <v-icon
          color="secondary"
          variant="text"
          @click="bulkUploadSuccess = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="bulkDeleteSuccess"
      data-testid="bulkDeleteSuccessToast"
      color="primary"
      top
      timeout="2000"
    >
      Partner Deleted successfully!
      <template v-slot:action>
        <v-icon
          color="secondary"
          variant="text"
          @click="bulkDeleteSuccess = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <v-row>
      <v-col>
        <v-overlay
          v-if="loading || bulkDeleteLoading"
          class="style-position"
        >
          <v-progress-circular
            indeterminate
            class="style-loader"
            :size="80"
            absolute
            color="primary"
          />
        </v-overlay>
      </v-col>
    </v-row>
    <sequential-entrance from-top>
      <v-row>
        <v-col
          sm="12"
          md="12"
          lg="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-group"
            inline
          >
            <template v-slot:after-heading>
              <div class="text-h3 font-weight-light">
                Partner Management Dashboard
              </div>
            </template>
            <v-data-table
              v-model="selectedPartners"
              item-key="key"
              :item-class="rowClass"
              :headers="headers"
              :items="getpartners"
              :search="search"
              sort-by="created"
              :sort-desc="true"
              class="elevation-1"
              show-expand
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              calculate-widths
              show-select
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <!-- <v-toolbar-title>Partners</v-toolbar-title> -->
                  <!-- <v-divider
                    class="mx-4"
                    inset
                    vertical
                  /> -->
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search partners"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                  <v-dialog
                    v-model="uploadDialog"
                    max-width="800px"
                  >
                    <v-overlay
                      v-if="isUploading"
                      data-testid="bulkUpload-overlay"
                    >
                      <v-row
                        class="mt-12"
                      >
                        <v-col class="d-flex justify-center">
                          <ellipse-progress
                            :loading="isUploading"
                            :size="180"
                            img-src="src/assets/images/termlife-icon.png"
                            thickness="5"
                            empty-thickness="3"
                            line-mode="out 5"
                            legend="false"
                            animation="rs 700 1000"
                            font-size="1.5rem"
                            color="#00A1B7"
                            empty-color-fill="transparent"
                            filename="termlife-icon.png"
                          >
                          </ellipse-progress>
                        </v-col>
                      </v-row>
                    </v-overlay>

                    <template v-slot:activator="{ on, attrs }">
                      <v-col cols="auto">
                        <v-btn
                          data-testid="bulkUploadBtn"
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Bulk Upload
                        </v-btn>
                      </v-col>
                    </template>
                    <v-card data-testid="bulkUploadDialog">
                      <v-card-title>
                        <span class="text-h5">Upload Partners</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-form>
                            <v-form
                              ref="uploadForm"
                              data-testid="bulkUploadForm"
                            >
                              <v-file-input
                                :key="Math.floor(Math.random()*100)"
                                label="Upload CSV file"
                                accept=".csv"
                                prepend-icon="mdi-file-upload"
                                :rules="[fileRules.required, fileRules.csvFile]"
                                data-testid="bulkUploadFile"
                                @change="handleFileUpload"
                              ></v-file-input>
                            </v-form>
                            <v-alert
                              v-if="csvError"
                              type="error"
                              data-testid="bulkUploadError"
                            >
                              {{ csvError }}
                            </v-alert>

                            <v-data-table
                              v-if="parsedData.length"
                              v-model="selected"
                              data-testid="bulkUploadTable"
                              :headers="csvHeaders"
                              item-key="index"
                              class="csvTable"
                              :items="parsedData"
                              :item-class="getItemClass"
                              show-select
                              disable-pagination
                            >
                            </v-data-table>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          data-testid="bulkUploadCancel"
                          @click="closeUpload"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          data-testid="bulkUploadCsv"
                          :disabled="csvError!==''||isUploading||parsedData.length===0"
                          @click="uploadCSV"
                        >
                          Upload
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="dialog"
                    max-width="800px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-col cols="auto">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          data-testid="newPartnerButton"
                          v-on="on"
                        >
                          New Partner
                        </v-btn>
                      </v-col>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <ValidationObserver ref="partnerObserver">
                            <v-form
                              ref="form"
                              v-model="valid"
                              lazy-validation
                              data-testid="singlePartnerForm"
                            >
                              <v-text-field
                                v-model="editedItem.name"
                                outlined
                                :rules="nameRules"
                                label="Your Name"
                                data-testid="name"
                                required
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.company"
                                data-testid="company"
                                outlined
                                :rules="[v => !!v || 'Company Name is required']"
                                label="Company Name"
                                required
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.companywebsite"
                                data-testid="website"
                                outlined
                                :rules="[v => !!v || 'Website is required']"
                                label="Company Website"
                                required
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.phone"
                                v-mask="'###-###-####'"
                                data-testid="phone"
                                outlined
                                :rules="phoneRules"
                                label="Phone Number"
                                required
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.email"

                                data-testid="email"
                                :rules="emailRules"
                                outlined
                                label="E-mail"
                                required
                              ></v-text-field>

                              <v-select
                                v-model="editedItem.industries"
                                data-testid="industries"
                                multiple
                                outlined
                                :items="products"
                                :rules="[v => !!v || 'Industry is required']"
                                label="Industry"
                                required
                              ></v-select>
                              <v-text-field
                                v-model="editedItem.type"
                                data-testid="type"
                                outlined
                                label="Type"
                                required
                              ></v-text-field>

                              <v-select
                                v-model="editedItem.active"
                                data-testid="active"
                                outlined
                                :items="partnerstatus"
                                :rules="[v => !!v || 'Status is required']"
                                label="Status"
                                required
                              ></v-select>
                              <v-text-field
                                v-if="editedItem.active?.toLowerCase() === 'approved'"
                                v-model="editedItem.partnerurl"
                                :prefix="url"
                                readonly
                                data-testid="partnerURL"
                                outlined
                                label="Partner Link"
                                required
                                @click="copyItemURL(item)"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.notes"
                                data-testid="notes"
                                outlined
                                height="140"
                                label="Notes"
                              ></v-text-field>
                               <admin-email-options
                                :purchasePolicyOpt="editedItem.purchasePolicyOpt"
                                :knockOutOptOption="editedItem.knockOutOptOption"
                                :purchaseEmailOptions="editedItem.purchaseEmailOptions"
                                :knockoutEmailOptions="editedItem.knockoutEmailOptions"
                                @update="handleUpdate"
                              />
                            </v-form>
                          </ValidationObserver>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          data-testid="singlePartnerCancleButton"
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          data-testid="singlePartnerSaveButton"
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="dialogDelete"
                    max-width="600px"
                  >
                    <v-card>
                      <v-card-title class="text-h5">
                        Are you sure you want to delete this partner?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeDelete"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                        >
                          OK
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="dialogDeletePartner"
                    max-width="600px"
                  >
                    <v-card>
                      <v-card-title class="text-h5">
                        Are you sure you want to delete the selected partners?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closePartnerDelete"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deletePartnerConfirm"
                        >
                          OK
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2 custom-btn"
                      v-bind="attrs"
                      data-testid="delete-partners"
                      :disabled="selectedPartners?.length===0"
                      v-on="on"
                      @click="deletePartners"
                    >
                      Delete Partner
                    </v-btn>
                  </v-col>
                </v-toolbar>
              </template>
              <template
              v-slot:[`item.active`]="{ item }"
              >
                <v-chip
                  :color="getColor(item.active)"
                  dark
                  class="text-uppercase"
                >
                  {{ getStatusName(item.active) }}
                </v-chip>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                {{ `${item.type ?? ""}` }}
              </template>
              <template v-slot:[`item.partnerurl`]="{ item }">
                {{ `${item.partnerurl ?? ""}` }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="text-body-1 py-4"
                >
                  <div>
                    <span class="font-weight-bold">Partner URL:</span>
                    <a
                      :href="getUrl(item)"
                      target="_blank"
                    > {{ getUrl(item) }}</a>
                  </div>
                  <div>
                    <span class="font-weight-bold">Phone:</span> {{ item.phone }}
                  </div>
                  <div>
                    <span class="font-weight-bold">Website:</span> {{ ' ' }}
                    <a
                      :href="externalUrl(item?.companywebsite)"
                      target="_blank"
                    >{{ item.companywebsite }}</a>
                  </div>
                  <div v-if="item?.industries">
                    <span class="font-weight-bold">Industries:</span> {{ item.industries.join(",") }}
                  </div>
                  <div v-if="item?.limitedLineTravelInsuranceLicense">
                    <span class="font-weight-bold">Limited Line Travel Insurance License:</span>
                    {{ item?.limitedLineTravelInsuranceLicense }}
                  </div>
                  <div v-if="item?.limitedLineTravelInsuranceLicenseNumber">
                    <span class="font-weight-bold">Limited Line Travel Insurance License Number:</span>
                    {{ item?.limitedLineTravelInsuranceLicenseNumber }}
                  </div>
                  <div v-if="item?.approvedTimestamp">
                    <span class="font-weight-bold">Approved on:</span> {{
                      new Intl.DateTimeFormat('en-US', {
                        dateStyle: 'full',
                        timeStyle: 'long'
                      }).format(item?.approvedTimestamp)
                    }}
                  </div>
                  <div v-if="item?.notes">
                    <span class="font-weight-bold">Notes:</span> {{ item.notes }}
                  </div>
                </td>
              </template>
              <template v-slot:[`item.campaigns`]="{ item }">
                <v-btn
                  v-if="item.active.toLowerCase()==='approved'"
                  outlined
                  color="indigo"
                  class="mr-2"
                  :to="'/partnercampaigns/' + item?.key"
                >
                  <v-icon>mdi-handshake</v-icon>
                  Campaigns
                </v-btn>
              </template>
              <template
                v-slot:[`item.actions`]="{ item }"
              >
                <div
                  class="d-flex justify-center"
                >
                  <v-icon
                    class="mr-2"
                    color="purple"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    class="mr-2"
                    color="red"
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                  <v-expand-x-transition>
                    <v-tooltip
                      :color="tooltipColor"
                      right
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-icon
                          v-if="item?.active?.toString()?.toLowerCase()==='approved'"
                          class="mr-2"
                          color="purple"
                          v-bind="attrs"
                          v-on="on"
                          @click="copyItemURL(item)"
                          @mouseover="onTooltipMouseOver"
                        >
                          mdi-link
                        </v-icon>
                      </template>
                      <span>{{ tooltipText }}</span>
                    </v-tooltip>
                  </v-expand-x-transition>
                </div>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
      </v-row>
    </sequential-entrance>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="partnerErrorDialog"
          transition="dialog-bottom-transition"
          max-width="700"
          persistent
        >
          <v-card class="pa-4">
            <v-row>
              <v-col class="text-center text-h3 d-flex justify-center">
                {{ error }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-2 mt-4 font-weight-bold"
                    @click="
                      partnerErrorDialog = false;
                      clearError();
                    "
                  >
                    Close
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { emailRegex } from '@/consts/regex'
  import moment from 'moment'
  import { firebase } from '@firebase/app'
  import { getCopyURL, partnerProducts } from '../../../utils/common'
  import item from '../../../components/base/Item.vue'
  import { logger } from 'firebase-tools/lib/logger'
  import AdminEmailOptions from './AdminEmailOptions.vue'
  export default {
    components: { AdminEmailOptions },
    data: () => ({
      selected: [],
      selectedPartners: [],
      selectAll: false,
      indeterminate: false,
      partnerErrorDialog: false,
      refresh: 0,
      url: `${window.location.origin}/partner/`,
      bulkUploadSuccess: false,
      bulkDeleteSuccess: false,
      dialog: false,
      uploadDialog: false,
      dialogDelete: false,
      dialogDeletePartner: false,
      singleExpand: true,
      expanded: [],
      file: null,
      isUploading: false,
      bulkDeleteLoading: false,
      parsedData: [],
      csvHeaders: [],
      csvError: '',
      fileRules: {
        required: (value) => !!value || 'File is required.',
        csvFile: (value) => {
          if (!value) return true
          const valid = value.name.endsWith('.csv')
          return valid || 'File must be a CSV.'
        },
      },
      search: '',
      headers: [
        { text: '', value: 'data-table-expand', width: '1%' },
        {
          text: 'Type',
          value: 'type',
          align: 'center',
          sortable: true,
          width: '5%'
        },
        {
          text: 'Partner ID',
          value: 'partnerurl',
          align: 'center',
          sortable: true,
          width: '5%'
        },
        { text: 'Created', value: 'created', align: 'center', sortable: true, width: '9%' },
        { text: 'Company Name', value: 'company', align: 'center', sortable: true, width: '15%' },
        { text: 'Client Name', value: 'name', align: 'center', sortable: true, width: '15%' },
        { text: 'Email', value: 'email', align: 'center', sortable: true, width: 'auto' },
        { text: 'Phone', value: 'phone', align: 'center', sortable: true, width: 'auto' },
        { text: 'Status', value: 'active', align: 'center', sortable: true, width: '5%' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: '5%' },
        { text: 'Campaigns', value: 'campaigns', align: 'center', sortable: false, width: '10%' },
      ],
      valid: false,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      phoneRules: [
        v => !!v || 'Phone Number is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => emailRegex.test(v) || 'E-mail must be valid',
      ],
      products: partnerProducts,
      partners: [],
      partnerstatus: ['NEW', 'APPROVED', 'DECLINED', 'UNQUALIFIED'],
      editedIndex: -1,
      max: 50,
      allowSpaces: false,
      unique: true,
      filterchars: true,
      onlyspace: false,
      tooltipText: 'Copy partner URL',
      tooltipColor: '#000000',

      editedItem: {
        key: '',
        type: '',
        company: '',
        companywebsite: '',
        email: '',
        industries: '',
        limitedLineTravelInsuranceLicense: '',
        limitedLineTravelInsuranceLicenseNumber: '',
        message: '',
        name: '',
        notes: '',
        partnerurl: '',
        phone: '',
        purchasePolicyOpt: false,
        knockOutOptOption: false,
        purchaseEmailOptions: '',
        knockoutEmailOptions: ''
      },
      defaultItem: {
        key: '',
        type: '',
        company: '',
        companywebsite: '',
        email: '',
        industries: '',
        limitedLineTravelInsuranceLicense: '',
        limitedLineTravelInsuranceLicenseNumber: '',
        message: '',
        name: '',
        notes: '',
        partnerurl: '',
        phone: '',
        purchasePolicyOpt: false,
        knockOutOptOption: false,
        purchaseEmailOptions: '',
        knockoutEmailOptions: ''
      },
    }),
    computed: {
      item () {
        return item
      },
      error () {
        const error = this.$store.getters.error || ''
        return error
      },
      menuforstatus () {
        return [
          {
            text: 'Sign Ups by Partner',
            value: 'deposits',
            color: 'green',
            icon: 'mdi-account-cash-outline',
            nav: '/ReportsPartnersAccounts'
          },
          {
            text: 'Sales by Partner',
            value: 'cit',
            color: 'purple',
            icon: 'mdi-transfer-right',
            nav: '/ReportPartnersSales'
          },
          {
            value: 'cashin',
            text: 'Traffic By Partner',
            color: 'orange',
            icon: 'mdi-calendar-multiple',
            nav: '/ReportPartnersTraffic'
          },
          {
            value: 'monthly',
            text: 'Monthly Volume',
            color: 'red',
            icon: 'mdi-chart-areaspline',
            nav: '/ReportPartnersVolume'
          },
          {
            value: 'bncspy',
            text: 'BNC Applicant Spy',
            color: 'black',
            icon: 'mdi-incognito',
            nav: '/BNCSpy'
          }
        ]
      },
      validForm () {
        let validform = false
        if (this.email && this.phone && this.company && this.companywebsite && this.industries) {
          validform = true
        }
        return validform
      },
      rules () {
        const rules = []
        let urls = this.partners.map(entry => entry?.partnerurl)
        if (this.unique) {
          const rule =
            v => (!urls.includes(v)) ||
              'Values must be unique and not be an existing site name'

          rules.push(rule)
        }
        if (!this.onlyspace) {
          const rule =
            v => v.trim().length || 'Name can not be only whitespace characters'

          rules.push(rule)
        }
        if (this.max) {
          const rule =
            v => (v || '').length <= this.max ||
              `A maximum of ${this.max} characters is allowed in a site name`

          rules.push(rule)
        }

        if (!this.allowSpaces) {
          const rule =
            v => !(/[ ]/.test(v)) || 'No spaces allowed'
          rules.push(rule)
        }
        if (this.filterchars) {
          const rule =
            v => !v.match(/\\t|[#\$\.]/gi) || ' $ # [ ] tabs are not allowed in a site name'
          rules.push(rule)
        }
        return rules
      },
      URLrules () {
        const rules = []
        let partnerURLs = this.$store.getters.getLoadedBlanketPartners.map(entry => entry.partnerurl)

        if (this.unique) {
          const rule =
            v => (!partnerURLs.includes(v)) ||
              'URL must be unique and not be an existing partner URL'

          rules.push(rule)
        }
        if (!this.onlyspace) {
          const rule =
            v => v.trim().length || 'URL can not be only whitespace characters'

          rules.push(rule)
        }
        if (this.max) {
          const rule =
            v => (v || '').length <= this.max ||
              `A maximum of ${this.max} characters is allowed in a site name`

          rules.push(rule)
        }

        if (!this.allowSpaces) {
          const rule =
            v => !v.match(/^\S+$/) || 'No spaces are allowed'
          rules.push(rule)
        }
        if (this.filterchars) {
          const rule =
            v => !v.match(/\\t|[#\$\.]/gi) || ' $ # [ ] tabs are not allowed in a URL'
          rules.push(rule)
        }
        return rules
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New Partner' : 'Edit Partner'
      },
      loading () {
        // return true
        return this.$store.getters.getloading
      },
      getpartners () {
        this.partners = []

        if (this.$store.getters.getLoadedBlanketPartners) {
          this.partners = this.$store.getters.getLoadedBlanketPartners
          this.partners.forEach((entry, index) => {
            Object.assign(entry, { created: moment(entry.timestamp).format('YYYY-MM-DD HH:mm') })
          })
        }

        return this.partners
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      uploadDialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.$store.dispatch('loadblanketpartners')
    },
    methods: {
      toggleAll () {
        console.log('Selected toggle')
        /* if (this.selectAll) {
          this.selected = []
        } else {
          this.selected = this.parsedData.slice()
        }
        this.selectAll = !this.selectAll */
      },
      onSelectedChanged (value) {
        console.log(value)
        /*       if (value.length === this.parsedData.length) {
          this.selectAll = true
          this.indeterminate = false
        } else if (value.length > 0) {
          this.indeterminate = true
          this.selectAll = false
        } else {
          this.indeterminate = false
          this.selectAll = false
        } */
      },
      getItemClass ({ duplicate }) {
        return duplicate ? 'duplicate' : ''
      },
      gettopurl (nav) {
        let url = nav
        return url
      },

      initialize () {
        this.partners = this.$store.getters.getLoadedBlanketPartners
      },

      editItem (item) {
        this.editedIndex = this.partners.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.partners.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deletePartners () {
        this.dialogDeletePartner = true
      },
      closePartnerDelete () {
        this.dialogDeletePartner = false
      },
      async deletePartnerConfirm () {
        try {
          const getDeleteIds = this.selectedPartners.map((item) => item.key).filter(Boolean)
          const ref = firebase.database().ref('partners')
          const snapshot = await ref.once('value')
          const data = snapshot.val()
          const updates = {}
          if (Object.keys(data)?.length > 0 && getDeleteIds?.length > 0) {
            this.bulkDeleteLoading = true
            this.closePartnerDelete()
            Object.keys(data).forEach((key) => {
              if (getDeleteIds.includes(key)) {
                updates[`${key}/isDeleted`] = true
              }
            })
            if (Object.keys(updates).length > 0) {
              await ref.update(updates)
              this.closePartnerDelete()
              this.selectedPartners = []
              this.bulkDeleteLoading = false
              this.bulkDeleteSuccess = true
              console.log('Partners successfully marked as deleted.')
            } else {
              this.bulkDeleteLoading = false
              this.closePartnerDelete()
              console.log('No matching partners found or no updates needed.')
            }
          }
        } catch (e) {
          this.bulkDeleteLoading = false
          this.closePartnerDelete()
          console.log('error while deleting the partners', e)
        }
      },
      async deleteItemConfirm () {
        this.partners.splice(this.editedIndex, 1)
        // this.$store.dispatch('removerelease', this.editedItem)
        await firebase.database().ref(`partners/${this.editedItem.key}`).child('isDeleted').set(true)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.$refs.form.reset()
      },
      closeUpload () {
        this.$refs.uploadForm.reset()
        this.uploadDialog = false
        this.isUploading = false
        this.parsedData = []
        this.csvHeaders = []
        this.file = null
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async uploadCSV () {
        this.isUploading = true
        try {
          const data = this.selected
          const response = await firebase.functions().httpsCallable('uploadPartners')(data)
          const { data: { haserror, message } } = response
          if (haserror) {
            this.csvError = message
            return
          }
          this.bulkUploadSuccess = true
          this.selected = []
          this.parsedData = []
          this.closeUpload()
          await this.$store.dispatch('loadblanketpartners')
        } catch ({ message }) {
          this.csvError = 'CSV contains invalid data'
        } finally {
          //  this.bulkUploadSuccess = false
          this.isUploading = false
          this.file = null
        }
      },
      async save () {
        const [observer1Result, formResult] = await Promise.all([
          this.$refs.partnerObserver.validate(),
          this.$refs.form.validate()
        ])
        console.log('observer1Result', observer1Result)
        console.log('formResult', formResult)
        if (!observer1Result || !formResult) {
          return
        }
        if (this.editedIndex > -1) {
          Object.assign(this.partners[this.editedIndex], this.editedItem)
          await this.$store.dispatch('updateBlanketPartner', this.editedItem)
        } else {
          await this.$store.dispatch('createBlanketPartner', {
            ...this.editedItem,
            partnerurl: new Date().getTime(),
            type: this.editedItem.type !== '' ? this.editedItem.type : ''
          })
          if (this.error) {
            this.partnerErrorDialog = true
            return
          } else {
            this.partners.push(this.editedItem)
          }
        }
        this.$store.dispatch('loadblanketpartners')
        this.refresh += 1
        this.close()
      },

      onTooltipMouseOver () {
        // Reset to defaults
        this.tooltipText = 'Copy partner URL'
        this.tooltipColor = '#000000'
      },
      splitArrayIntoChunks (array, chunkSize) {
        const chunks = []
        for (let i = 0; i < array.length; i += chunkSize) {
          const chunk = array.slice(i, i + chunkSize)
          chunks.push(chunk)
        }
        return chunks
      },
      async copyItemURL (item) {
        let { partnerurl = '' } = item
        try {
          if (partnerurl) {
            await navigator.clipboard.writeText(getCopyURL(item))
            this.tooltipText = 'Copied!'
            this.tooltipColor = 'success'
          }
        } catch (e) {
          this.tooltipText = 'Error! Cannot copy!'
          this.tooltipColor = 'error'
        }
      },

      rowClass (item) {
        return 'row-h-60'
      },

      getColor (active) {
        if (active?.toLowerCase() === 'approved') return 'green'
        if (active?.toLowerCase() === 'new') return 'blue'
        if (active === false) return 'blue'
        return 'red'
      },

      getStatusName (active) {
        if (active === false) return 'NEW'
        return active
      },
      handleFileUpload (file) {
        this.file = file
        this.onFileChange()
      },
      onFileChange () {
        this.csvError = ''
        if (this.$refs.uploadForm.validate()) {
          this.parseCSV()
        }
      },
      prioritizeDuplicates () {
        const emailCount = {}
        const alreadyExistsCount = {}
        this.parsedData.forEach(row => {
          const alreadyExists = this.partners.find(({ email }) => row.email === email)
          if (alreadyExists) {
            alreadyExistsCount[row.email] = (alreadyExistsCount[row.email] || 0) + 1
          }
          if (row.email) {
            emailCount[row.email] = (emailCount[row.email] || 0) + 1
          }
        })

        this.parsedData.forEach((row, index) => {
          row.index = index
          if (alreadyExistsCount[row.email] > 1) {
            row.alreadyExists = true
          } else {
            row.alreadyExists = false
          }
          if (emailCount[row.email] > 1) {
            row.duplicate = true
          } else {
            row.duplicate = false
          }
        })
        const sorted = this.parsedData.sort((a, b) => {
          if (a.duplicate && !b.duplicate) return -1
          if (b.duplicate && !a.duplicate) return 1
          return 0
        })

        // this.selectAll = this.parsedData.find(({ duplicate }) => !duplicate)
        this.selected = sorted.filter(({ duplicate }) => !duplicate)
        // Sort by duplicate status and by original order if needed
        if (Object.keys(alreadyExistsCount).length > 0) {
          const emails = Object.keys(alreadyExistsCount).map(email => {
            return email
          })
          this.csvError = `Duplicate emails found ${emails.join(', ')}`
        } else {
          this.csvError = ''
        }
        return this.parsedData.sort((a, b) => {
          if (a.duplicate && !b.duplicate) return -1
          if (b.duplicate && !a.duplicate) return 1
          return 0
        })
      },
      parseCSV () {
        if (!this.file) return
        const reader = new FileReader()
        const expectedHeaders = [
          'active',
          'company',
          'companywebsite',
          'email',
          'industries',
          'limitedLineTravelInsuranceLicense',
          'limitedLineTravelInsuranceLicenseNumber',
          'message',
          'first_name',
          'last_name',
          'notes',
          'phone',
          'type',
          'virtgate_partner_id',
          'company_id',
        ]
        reader.onload = (event) => {
          const text = event.target.result
          const rows = text.trim().split('\n')
          const headers = rows[0].split(',').map(header => header.replace(/[\n\t\r]/, ''))

          // Validate headers
          const missingHeaders = expectedHeaders.filter(header => !headers.includes(header))
          const incorrectHeaders = headers.filter(header => !expectedHeaders.includes(header))
          if (missingHeaders.length || incorrectHeaders.length) {
            this.csvError = 'CSV file contains incorrect or missing headers.'
            return
          }
          const validHeaders = expectedHeaders.every(header => headers.includes(header)) && headers.length === expectedHeaders.length
          if (!validHeaders) {
            this.csvError = 'Invalid CSV headers. Please ensure the CSV contains the correct headers.'
            return
          }
          this.csvHeaders = headers.map((header) => ({
            text: header.trim(),
            value: header.trim(),
          }))
          this.csvHeaders = ['Select', ...this.csvHeaders]
          this.parsedData = rows.slice(1).map((row) => {
            const values = row.split(',')
            const obj = {}
            headers.forEach((header, index) => {
              obj[header?.trim()] = values[index]?.trim()
            })
            return obj
          })
          if (this.parsedData.length > 300) {
            this.csvError = 'Max limit is 300'
            this.file = null
            this.parsedData = []
            return
          }
          this.prioritizeDuplicates()
        }

        reader.readAsText(this.file)
      },

      externalUrl (url) {
        if (url && !/^https?:\/\//i.test(url)) {
          return 'http://' + url
        }

        return url
      },
      clearError () {
        this.partnerErrorDialog = false
        this.$store.dispatch('clearError')
      },
      getUrl (item) {
        // console.log('getUrl', item)
        return getCopyURL(item)
      },
      handleUpdate ({ prop, value }) {
        if (prop in this.editedItem) {
          this.$set(this.editedItem, prop, value)
        }
      }
    },
  }
</script>

<style lang="scss">
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
}
.policy-numbers {
  font-size: 36px;
}

.v-application--is-ltr .v-text-field__prefix {
  padding-right: 0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr.row-h-60 td {
  height: 60px;
}
.theme--light.v-data-table tbody tr.v-data-table__selected.duplicate,tr.duplicate{
  background: red;
  color: #fff;
}
tr.duplicate:hover,.theme--light.v-data-table tbody tr.v-data-table__selected.duplicate:hover{
  background: #ff3737 !important;
}
.csvTable{
  max-height: 300px;
  overflow: auto;
}
.style-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
}
.style-position{
  z-index: 5000!important;
}

</style>
